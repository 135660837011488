<template>
    <!--<div id="sidebar" class="desktop-only">-->
    <div id="sidebar">
        <div class="sidebar-inner">

            <!-- sidebar dettaglio -->
            <div v-if="this.$route.params.propertyId"><SidebarDetail :resultsFiltered="resultsFiltered" :inSalabamInfoBox="inSalabamInfoBox" /></div>
            <!-- sidebar dettaglio -->


            <!-- sidebar checkout/conferma -->
            <div v-else-if="
                this.$route.name == 'checkout' | 
                this.$route.name == 'confirm' |
                this.$route.name == 'reservations'
                "><SidebarCheckout :bookingData="this.$store.state.bookingData" v-on:sidebar-order-confirmed="$emit('sidebar-order-confirmed', {})"/></div>
            <!-- sidebar checkout/conferma -->


            <!-- sidebar mappe -->
            <div v-else-if="this.$route.params.slug !== undefined">
                <SidebarSearch
                    :resultsFiltered="resultsFiltered"
                    :inSalabamInfoBox="inSalabamInfoBox"
                />
            </div>
            <!-- sidebar mappe -->
            

            <!-- sidebar ricerca -->
            <div v-else>
                <SidebarSearch
                    :resultsFiltered="resultsFiltered"
                    :possibleProperties="possibleProperties"
                    :inSalabamInfoBox="inSalabamInfoBox"
                />
            </div>
            <!-- sidebar ricerca -->

        </div>
    </div>
</template>

<script>
    //import SidebarSearch from './sidebar-search'
    //import SidebarDetail from './sidebar-detail'
    //import SidebarCheckout from './sidebar-checkout'

    export default {
        name: 'sidebar',
        components:
        {
          SidebarSearch: () => import('./sidebar-search').then(function(SidebarSearch) {
            if(process.env.VUE_APP_MODE != 'production') window.console.log('async: SidebarSearch Component loaded')
            return SidebarSearch
          }),
          SidebarDetail: () => import('./sidebar-detail').then(function(SidebarDetail) {
            if(process.env.VUE_APP_MODE != 'production') window.console.log('async: SidebarDetail Component loaded')
            return SidebarDetail
          }),
          SidebarCheckout: () => import('./sidebar-checkout').then(function(SidebarCheckout) {
            if(process.env.VUE_APP_MODE != 'production') window.console.log('async: SidebarCheckout Component loaded')
            return SidebarCheckout
          }),
        },
        props:
        {
          resultsFiltered: Array,
          possibleProperties: Number,
          inSalabamInfoBox: Object,
        },
        mounted()
        {
        }
    }
</script>

<style lang="scss">

    // colore "success" per il pulsante di tutte le sidebar checkout (solo con la customizzazione attiva)
    body.customized {
      #sidebar-checkout,
      #sidebar-etc,
      #sidebar-dc {
      .btn-secondary {
          background-color: $success;
          color: $white !important;
        }
      }
    }
    
    #sidebar
    {
        display: block;
        width:100%;
        position:relative;
        .sidebar-inner
        {
            width:100%;
        }
        .sidebarBlock
        {
            display: block;
            width:100%;
            &>*
            {
                margin-bottom:1.5rem;
            }
            .panel-white
            {
                border-radius: $border-radius-lg;
                overflow: hidden;
                box-shadow: none;
            }
            .search-form-filters .common-title
            {
                @include customize-bordered-element("secondary");
            }
            @include media-breakpoint-up(lg)
            {
                &>*
                {
                    margin-bottom:2.5rem;
                }
            }
        }
        @include media-breakpoint-down(md)
        {
            padding-left:$grid-gutter-width/2;
            padding-right:$grid-gutter-width/2;
        }
        @include media-breakpoint-up(lg)
        {
            display:flex;
            width:40%;
            flex:40%;
            max-width: 540px;
            .sidebar-inner
            {
            height:100%;
            padding-right:0;//$grid-gutter-width/2;
            }  
        }
        @include media-breakpoint-up(lg)
        {
            width:30%;
            flex:30%;
        }
        @include media-breakpoint-up(lg)
        {
            padding-right:$grid-gutter-width/2;
        }
        @include media-breakpoint-up(xl)
        {
            padding-right:$grid-gutter-width;
        }
    }
</style>